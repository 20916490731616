import { inputBaseClasses } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useFaucetStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: 728,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(9),
  },
  titleWrap: {
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'center',
    alignItems: 'center',
  },
  tacTitleWrap: {
    gap: 0,
  },
  titleImg: {
    width: 47,
    height: 44,
  },
  tacMascot: {
    marginTop: theme.spacing(-4.5),
  },
  title: {
    background: 'linear-gradient(90deg, #8D37BD 0%, #241C8E 50%)',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textFillColor: 'transparent',
  },
  form: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  addressField: {
    [`& .${inputBaseClasses.input}`]: {
      '&:-webkit-autofill': {
        boxShadow: 'none',
        caretColor: 'inherit',
        WebkitTextFillColor: 'inherit',
      },
      '&:first-of-type': {
        marginLeft: 0,
      },
    },
  },
  submitBtn: {
    whiteSpace: 'nowrap',
    minWidth: 240,

    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    '&&': {
      minHeight: 58,
    },
  },
  messageBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  messageBoxError: {
    fontSize: 16,
  },
}));
